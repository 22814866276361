import styled from "styled-components";

const G2SectionWrapper = styled.section`
  max-width: 85%;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 0px;

  .heading-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
    color: white;
    font-size: 32px;
    padding: 15px 0px;
    background-color: #03baed;
    border-radius: 40px;
    width: 0 auto;
    text-align: center;

    @media screen and (max-width: 1189px) {
      font-size: 28px;
    }

    @media screen and (max-width: 576px) {
      font-size: 16px;
    }

    @media screen and (max-width: 460px) {
      font-size: 10px;
    }
  }
  .text {
    font-size: 22px;
    font-weight: 400;
    text-align: center;

    @media screen and (max-width: 576px) {
      font-size: 16px;
    }

    @media screen and (max-width: 460px) {
      font-size: 14px;
    }
  }
  .text b {
    font-weight: bold;
  }

  .text i {
    font-style: italic;
  }

  .worldMapImg {
    width: 100%;
  }

  @media screen and (max-width: 1189px) {
    max-width: 80%;
  }

  @media screen and (max-width: 576px) {
  }

  @media screen and (max-width: 460px) {
  }
`;

export default G2SectionWrapper;
