import styled from "styled-components";

const RatingsWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 80%;
  margin: 10px auto;
  padding: 0;
  font-size: 16px;
  gap: 25px;

  .nga-circle {
    text-align: center;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    padding: 4px;
    margin: 2px;
    display: inline-block;
  }
  .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  }
`;

export const CardContainer = styled.div`
  max-width: 50%;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 30px;
  border: none;

  a {
    color: #03baed;
  }
  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  @media only screen and (max-width: 768px) {
    padding: 15px;
    max-width: unset;
    width: 100%;
    margin-bottom: 20px;
    .logo {
      width: 25%;
    }
    h3 {
      font-size: 1rem;
    }
    p {
      font-size: 0.8rem;
    }
  }

  @media only screen and (max-width: 400px) {
    padding: 20px;
    max-width: unset;
    width: 100%;
    margin-bottom: 20px;
    .logo {
      width: 25%;
    }
    h3 {
      font-size: 0.8rem;
    }
    p {
      font-size: 0.6rem;
    }
  }
`;

export default RatingsWrapper;
