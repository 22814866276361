import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WorldMap from "../../../common/assets/image/saasModern/HomePageImg/G2WorldMapSpring.svg";

import G2SectionWrapper from "./g2slider.style";

const G2Slider = () => {
  return (
    <G2SectionWrapper>
      <h2 className='heading-tag'>
        The Go-to Sales Technology for Businesses of All Sizes
      </h2>
      <p className='text'>
        Clodura is now{" "}
        <i>
          <b>#1-ranked Leader, High Performer, </b>
        </i>
        and{" "}
        <i>
          <b> Most Loved</b>{" "}
        </i>{" "}
        in <br /> <i>Sales Intelligence</i>, <i>Lead Intelligence</i>, and{" "}
        <i>Sales Engagement Solution </i> on G2.
      </p>
      <img className='worldMapImg' src={WorldMap} alt='WorldMap' />
    </G2SectionWrapper>
  );
};

export default G2Slider;
